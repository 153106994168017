import React from "react"
import Layout from "~/components/common/Layout"
import Quiz from "~/components/page/Quiz"
import { getBackLink, getHeaderTitles, getNextQuizLink, getNextTitle } from "~/services/pages"
import withProgress from "~/services/withProgress"

const Page = ({ myPage, mySubpage }) => {
  const questionNumber = 1
  const nextLink = getNextQuizLink(myPage, mySubpage, questionNumber)

  return (
    <Layout
      header={getHeaderTitles(myPage, mySubpage)}
      footer={{
        backLink: `${getBackLink(myPage, mySubpage)}`,
        nextText: `NEXT: ${getNextTitle(myPage, mySubpage)}`,
      }}
    >
      <Quiz
        quizNumber={"FOUR"}
        questionNumber={questionNumber}
        questionsTotal={5}
        question={"What is an example of a growth question?"}
        answers={[
          "Did you know giraffes can’t jump?",
          "What’s your favorite movie? ",
          "If you could have any superpower, what would it be?",
          "How did you do that?",
        ]}
        correctAnswerNumber={3}
        answerDetail={
          "Growth questions are open ended questions that allow people to think crtically about something and provide a thorough response in hopes of helping them learn and grow from the experience. "
        }
        nextLink={nextLink}
      ></Quiz>
    </Layout>
  )
}

export default withProgress(Page, 4, 4)
